export const profiles = {
   
    // Omicron
    Raj_Singh: {
        linkedIn: "https://www.linkedin.com/in/jasrajsingh1/",
        homeTown: "Fremont, CA",
        year: "4th",
        class: "Omicron",
        major: "Business Economics",
        minor: "Psychology",
        emphasis: "",
        practice: "Marketing/Management",
        pronouns: "He/Him/His",
        experience: {"Marketing & Management Intern":"Hajoca", "Marketing Specialist":"Nectar", "Business Development Intern": "Sencha Credit", "Ambassador" : "Discord", "CEO": "Ess Dee Consulting Inc", "Content Marketer": "Sencha Credit", "IT Receptionist": "Pioneer Tax & Accounting Services Inc.", "Associate Employee": "Which Wich Superior Sandwiches"},
        campusInvolvements: {"Mentor": "Management Information Student Society", "External Affairs Intern": "Marketing Association", "Mentee": "Management Information Student Society"},
        interests: ["Gaming", "Fashion", "Skincare", "Friends :D"],
        whyLPN: "I joined LPN mostly for the community at first because I came in as a freshman during the Pandemic meaning it was difficult to meet people at first. Additionally, I was extremely lost in terms of my professional career, and I needed that community of driven individuals to look up to and motivate me.",
        img: require("../../../../assets/actives/brothers/Raj_Singh.jpg")
    },
    Vivian_Tran: {
        linkedIn: "https://www.linkedin.com/in/vivianltrvnn/",
        homeTown: "Elk Grove, CA",
        year: "4th",
        class: "Omicron",
        major: "Business Administration",
        minor: "Psychological Science ",
        emphasis: "Organization and Management",
        practice: "Project/Program Management",
        pronouns: "She/Her/Hers",
        experience: {"IT Project Management Intern":"ServiceNow", "People Development Intern":"Dow Jones","Talent Acquisition Intern":"Unifi","Digital Marketing Intern": "Kanerani"},
        campusInvolvements: {"President":"Merage Undergraduate Student Association", "Fundraising Chair": "Alpha Kappa Delta Phi", "Professional Development Chair" : "Alpha Kappa Delta Phi","VP of Graduate and Alumni Relations": "Merage Undergraduate Student Association", "VP of Community Development": "Merage Undergraduate Student Association", "VP of Outreach": "Human Resources Management Association"},
        interests: ["Escape Rooms", "Board Games", "Traveling", "Tennis"],
        whyLPN: "LPN gave me a community that pushes me to become the best version of myself and friends that I can call family. I know that no matter where I go I have endless encouragement and support.",
        img: require("../../../../assets/actives/brothers/Vivian_Tran.jpg")
    },
 
    // Pi
    Jamie_Zhao: {
        linkedIn: "https://www.linkedin.com/in/jamiezh/",
        homeTown: "San Francisco, CA",
        year: "4th",
        class: "Pi",
        major: "Business Economics",
        minor: "Management",
        emphasis: "",
        practice: "Marketing",
        pronouns: "She/Her/Hers",
        experience: {"Interactive Producer Intern": "Walmart Connect", "Partnerships Intern":"UCI Esports", "Strategic Events Intern":"ServiceNow", "Social Media Intern":"Oigetit","Human Resources Intern":"UpCancer"},
        campusInvolvements: {"VP of External Affairs":"Management Information Student Society", "Member Relations Director":"Marketing Association","Recruiter - Winter Quarter":"Lambda Phi Nu"},
        interests: ["Dancing", "Video Games", "Trying out new foods"],
        whyLPN: "I chose to rush LPN because I really wanted to push myself out of my comfort zone. During my freshman year, I felt isolated and demotivated during quarantine. I wanted to push myself out of my slump by joining this fraternity that not only furthered myself professionally, but I also created so many connections that I treasure deeply. I know that I would not be where I am without LPN.",
        img: require('../../../../assets/actives/brothers/Jamie_Zhao.jpg')
    },
    Lilian_Luu: {
        linkedIn: "https://www.linkedin.com/in/lluu/",
        homeTown: "",
        year: "4th",
        class: "Pi",
        major: "Business Administration",
        minor: "",
        emphasis: "Marketing, Organization & Management",
        practice: "Public Relations, Marketing",
        pronouns: "She/Her/Hers",
        experience: {"Communications Intern":"Cruise", "Public Relations Intern":"Comcast", "Social Media Ads Specialist Intern":"Instaboost Media","Executive MBA Admissions Student Assistant":"Paul Merage School of Business"},
        campusInvolvements: {"ADD: Chief Executive Officer":"Lambda Phi Nu", "Executive Vice President":"Undergraduate Business Association","VP of Corporate Relations":"Undergraduate Business Association","Representative": "Merage Undergraduate Student Association","Information Associate":"Lambda Phi Nu"},
        interests: ["Cooking","Disney", "Skincare", "Carpool Karaoke", "Making Boba"],
        whyLPN: "LPN was my first community at UCI. All the brothers made me feel like I had a place and that connection has stuck. Professionally and personally, you won't find better coaches or cheerleaders to get you through the highs & lows. Ohana means family and no one gets left behind or forgotten here.",
        img: require('../../../../assets/actives/brothers/Lilian_Luu.jpg')
    },
    Lumin_Oo: {
        linkedIn: "https://www.linkedin.com/in/luminoo/",
        homeTown: "Stockton, CA",
        year: "4th",
        class: "Pi",
        major: "Business Administration",
        minor: "",
        emphasis: "Organization & Management, Information Systems",
        practice: "Consulting",
        pronouns: "He/Him/His",
        experience: {"Summer Scholar":"Deloitte", "Discovery Consulting Intern":"Deloitte"},
        campusInvolvements: {"Mentor": "Management Information Student Society","Vice President of Administration":"Undergraduate Business Association","VP of Marketing":"Undergraduate Business Association","Alumni & Campus Relations Director":"Marketing Association","Finance Director":"Lambda Phi Nu","Representative": "Merage Undergraduate Student Association", },
        interests: ["Sports", "Music", "Traveling", "Food", "Weightlifting"],
        whyLPN: "Joining LPN was one of the best decisions I made during my time in college. I am grateful to be a part of a community that is so supportive and inspiring. LPN has helped facilitate my growth both professionally and personally by enabling me to explore new things and step out of my comfort zone. The experiences I've gained and all the relationships I've formed are second to none.",
        img: require("../../../../assets/actives/brothers/Lumin_Oo.jpg")
    },

    //Rho
    Aaron_Lee: {
        linkedIn: "https://www.linkedin.com/in/kyngnlee/",
        homeTown: "Seoul, South Korea",
        year: "3rd",
        class: "Rho",
        major: "Business Administration",
        minor: "",
        emphasis: "Accounting",
        practice: "Corporate Law",
        pronouns: "He/Him/His",
        experience: {
            "External Affairs Office Student Assistant": "Paul Merage School of Business",
            "Financial Services Student Assistant": "Paul Merage Financial Services", 
            "Business Development Intern":"Oppti"},
        campusInvolvements: {
            "Weight Room Assistant": "Anteater Recreation Center",
            "Student Manager/Practice Player":"UCI Women's Basketball"
        },
        interests: ["Photography", "MMA", "Basketball", "Shoe Collecting"],
        whyLPN: "LPN provides a unique bond between its members that you won't find anywhere else. Despite our brothers having such a wide range of backgrounds, we’ve become one big family that can help each other professionally and make valuable memories. To me, LPN means safety. It always gives me a secure feeling to know that there is a group of people who will support my decisions and guide me through the right path.",
        img: require('../../../../assets/actives/brothers/Aaron_Lee.jpg')
    },
    Talia_Yip: {
        linkedIn: "https://www.linkedin.com/in/taliayip/",
        homeTown: "Arcadia, CA",
        year: "4th",
        class: "Rho",
        major: "Business Economics",
        minor: "Accounting",
        emphasis: "",
        practice: "Audit",
        pronouns: "She/Her/Hers",
        experience: {"Audit Intern ":"PWC", "Audit Intern":"Deloitte", "Dance Instructor":"United Spirit Association"},
        campusInvolvements: {"Chief Finance Officer":"Lambda Phi Nu", "External Vice President":"Accounting Association",  "Mentor":"Accounting Association", "Treasurer": "Beta Alpha Psi", "Recruiter":"Lambda Phi Nu","Finance Director":"Lambda Phi Nu"},
        interests: ["Dancing", "Disney", "Collecting gnomes", "Exploring Taco Truck"],
        whyLPN: "LPN was the first group of people I met at UC Irvine. Coming in as a second year during the pandemic, I had no idea how to navigate the business world or put myself out there. I chose LPN because the people were so welcoming and willing to lend a hand. Whether it is professionally or personally, I know that this group of individuals will support and guide me forever!",
        img: require('../../../../assets/actives/brothers/Talia_Yip.jpg')
    },
    Sydney_Yu: {
        linkedIn: "https://www.linkedin.com/in/sydneymyu/",
        homeTown: "Arcadia, CA",
        year: "4th",
        class: "Rho",
        major: "Business Economics",
        minor: "Accounting",
        emphasis: "",
        practice: "Audit",
        pronouns: "She/Her/Hers",
        experience: {"Audit  Intern":"EY", "Audit Intern":"PwC"},
        campusInvolvements: {"Chief Information Officer":"Lambda Phi Nu", "Director of Professional Development":"Accounting Association", "Chief Information Officer":"Lambda Phi Nu", "Career Catalyst Director":"Lambda Phi Nu","Secretary":"Accounting Association"},
        interests: ["Figure Skating", "Dance", "Upcycling Clothes","Watching Movies"],
        whyLPN: "When I first stepped foot on  the UCI campus, my initial goal was to focus solely on my professional development. However, with LPN, I not only found a group of people that would motivate me to grow professionally, but would also push me to step outside my comfort zone. Looking back, it amazes me how much I have grown since I came to UCI. I learned more in the span of 8 weeks than I have in my entire life, and I know my growth will only continue with the support of my fellow brothers.",
        img: require('../../../../assets/actives/brothers/Sydney_Yu.jpg')
    },
    Nicole_Nguyen: {
        linkedIn: "https://www.linkedin.com/in/nicolexn/",
        homeTown: "Saratoga, CA",
        year: "4th",
        class: "Rho",
        major: "Business Administration",
        minor: "Information & Computer Science",
        emphasis: "Marketing",
        practice: "Product Marketing",
        pronouns: "She/Her/Hers",
        experience: {"Product Marketing Co-Op" : "ServiceNow", "Data Solutions Marketing Intern":"Moody's Analytics","Marketing Strategy Intern":"ASUCI Media & Technology","Intern":"Automobuild"},
        campusInvolvements: {"President":"Product Association", "Co-VP of Fellowship":"Product Association","Chief Information Officer":"Lambda Phi Nu","Marketing Strategy Intern":"ASUCI Media & Technology","Representative":"Merage Undergraduate Student Association"},
        interests: ["Snowboarding", "Traveling", "Filmmaking", "Collecting Legos", "Video Games", "Art"],
        whyLPN: "LPN has defined my college experience in more ways than one. From cultivating new relationships to fostering growth in a community of driven and goal-oriented individuals, LPN has truly helped me to grow professionally and personally in ways that I could never have imagined. Since joining LPN, I have made countless memories and formed relationships and bonds that will last a lifetime. Each individual is so unique, talented, and special, and I couldn't have asked for a better community to be a part of.",
        img: require('../../../../assets/actives/brothers/Nicole_Nguyen.jpg')
    },
    Eddie_Rios: {
        linkedIn: "https://www.linkedin.com/in/eddierioss/",
        homeTown: "Los Angeles, CA",
        year: "3rd",
        class: "Rho",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Organization & Management",
        practice: "Project Management",
        pronouns: "He/Him/His",
        experience: {
            "Business Analyst & Project Management Intern": "Ingram Micro",
            "Research & Business Analyst Intern":"Mentor Global Consultants",
            "Student Assistant":"Paul Merage Specialty Masters Programs",
            "Research and Business Analyst":"Mentor Global Consultants"
        },
        campusInvolvements: {
            "VP of Academic Affairs, Representative" : "Merage Undergraduate Student Association",
            "VP of Operations, Mentor" : "Human Resources Management Association",
            "Mentor" : "Management Information Student Society",
            "Intern":"Undergraduate Business Association",
        },
        interests: ["Tennis", "Roller Skating", "Fashion Magazines", "Beaches"],
        whyLPN: "To me, LPN means community. There’s an endless amount of people to be inspired by and always someone to back you up. LPN has opened my eyes to the opportunities that I otherwise would’ve missed out on. Through LPN, I was able to find the person I want to be, and I now have the resources to strive towards becoming that person.",
        img: require('../../../../assets/actives/brothers/Eddie_Rios.jpg')
    },
    Kayla_Wong: {
        linkedIn: "https://www.linkedin.com/in/hfkaylawong/",
        homeTown: "San Lorenzo, CA",
        year: "4th",
        class: "Rho",
        major: "Business Administration",
        minor: "",
        emphasis: "Marketing",
        practice: "Brand Marketing",
        pronouns: "She/Her/Hers",
        experience: {
            "Global Brand Marketing Intern (Fenty Beauty)": "Kendo Brands", 
            "Digital Marketing Intern": "Drunk Elephant", 
            "Marketing Strategy Intern":"Accenture", 
            "Marketing Intern":"Volition Beauty"
        },
        campusInvolvements: {"President":"Undergraduate Business Association", "Representative":"Merage Undergraduate Student Assocation","VP of Professional Development":"Undergraduate Business Association", "Representative":"Merage Undergraduate Student Association"},
        interests: ["Skincare", "Reading", "Legos"],
        whyLPN: "Coming into a new environment, it is always scary to find a new community. I unintentionally stumbled upon lpn and the genuine people and the impactful conversations I had with them was unmatched to any experience I’ve had before. That continues to ring true today.",
        img: require('../../../../assets/actives/brothers/Kayla_Wong.jpg')
    },

    // Sigma

    // Hans_Chang: {
        // linkedIn: "https://www.linkedin.com/in/hans-chang/",
        // homeTown: "Taipei, Taiwan",
        // year: "3rd",
        // class: "Sigma",
        // major: "Business Economics",
        // minor: "Information and Computer Sciences",
        // emphasis: "",
        // practice: "Fintech, Marketing Analytics ",
        // pronouns: "He/Him/His",
        // experience: {"Risk Advisory Intern":"Deloitte","E-commerce Marketing Analysis Intern":"BenQ Corporation", "Accounting Assistant":"MediaTek Inc."},
        // campusInvolvements: {"Senior Advisor": "Taiwanese National Organization", "Strategy Director":"Lambda Phi Nu","Chief Finance Officer":"Taiwanese National Organization", "Media Team (Department of Marketing)":"Taiwanese National Organization", "Mentee":"Undergraduate Business Association",},
        // interests: ["Basketball", "Music", "Travel", "Cooking", "Skiing", "Formula 1", "Thrifting"],
        // whyLPN: "After having freshman year entirely online, I realized I didn't have much time left. I was not only trying to push myself out of my comfort zone but also looking for a community that could help me develop both personally and professionally. I am thankful for what LPN has taught me and shaped me into the person I am today.",
        // img: require('../../../../assets/actives/brothers/Hans_Chang.jpg'),
    // },
    Jaejin_Kim: {
        linkedIn: "https://https://www.linkedin.com/in/jaejink/",
        homeTown: "Busan, South Korea",
        year: "3rd",
        class: "Sigma",
        major: "Computer Science",
        minor: "",
        emphasis: "",
        practice: "Software Engineering, Web Development",
        pronouns: "He/Him/His",
        experience: {"Website Student Assistant":"UCI School of Medicine"},
        campusInvolvements: {"Corporate Organizer":"Hack at UCI","Webmaster":"Lambda Phi Nu","Executive Associate":"Lambda Phi Nu"},
        interests: ["Cooking","Sports","Gym","Late night walks","Nap"],
        whyLPN: "I chose LPN because I wanted to find a community where I feel like I belong in. I never felt like I had to be someone else other than myself here; I am grateful that I can truly be myself in this community. People here inspire and motivate me push myself to be a better version of myself everyday. ΛΦΝ is a community that you can't find anywhere else. I'm thankful to have every one of them in my life, and I am happy that I can call them my brothers.",
        img: require('../../../../assets/actives/brothers/Jaejin_Kim.jpg'),
    },
    Julie_Hsieh: {
        linkedIn: "https://www.linkedin.com/in/haonihsieh/",
        homeTown: "Taipei, Taiwan",
        year: "3rd",
        class: "Sigma",
        major: "Business Economics",
        minor: "Digital Arts",
        emphasis:"",
        practice: "Marketing",
        pronouns: "She/Her/Hers",
        experience: {"Marketing  Intern":"LnData", "Caller/Telephone Outreach Program":"UC Irvine University Advancement", "Marketing Intern":"Day and Night International CO., LTD"},
        campusInvolvements: {"President" : "Taiwanese National Organization", "Marketing Mentee":"Undergraduate Business Association", "Marketing Director":"Lambda Phi Nu", "Information Associater":"Lambda Phi Nu"},
        interests: ["Reading Webtoon", "Drawing", "Painting", "Hiking", "Running", "Online Shopping", "Skincare"],
        whyLPN: "I choose LPN because I want a family like community. A community that can inspire me and support me whenever I need help. LPN not only teach me what professionalism is but also let me step out of my comfort zone. I'm glad that I'm a part of this community, and I'm thankful for all the things that LPN gives me.",
        img: require('../../../../assets/actives/brothers/Julie_Hsieh.jpg'),
    },
    Justin_Chao: {
        linkedIn: "https://www.linkedin.com/in/justin-chao/",
        homeTown: "Anaheim, CA",
        year: "4th",
        class: "Sigma",
        major: "Business Administration",
        minor: "",
        emphasis: "Information Systems, Organization and Management",
        practice: "Consulting, Product Management",
        pronouns: "He/Him/His",
        experience: {
            "Business Analyst Intern": "Capital One",
            "Strategy & Operations Intern":"West Monroe",
            "HR Intern":"Western Digital",
            "Digital Marketing Manager":"Luxart Lights",
            "Product Marketing Intern, Social Media Manager":"Terra Pocket"
        },
        campusInvolvements: {
            "Product Management Lead": "Product Association",
            "VP of External Relations":"Human Resources Management Association",
            "Representative":"Merage Undergraduate Student Association",
            "Associate Consultant":"Students Consulting for Nonprofit Organizations"
        },
        interests: ["Basketball", "Spikeball","Golfing","Photography","Lifting","Golfing","Pool"],
        whyLPN: "LPN is a group of people that I can call my family. They are always there for me for both professional and personal help!",
        img: require('../../../../assets/actives/brothers/Justin_Chao.jpg'),
    },
    Ruby_Ko: {
        linkedIn: "https://www.linkedin.com/in/korubyko/",
        homeTown: "Taichung, Taiwan",
        year: "3rd",
        class: "Sigma",
        major: "Business Administration",
        minor: "",
        emphasis: "Marketing, Management",
        practice: "Marketing",
        pronouns: "She/Her/Hers",
        experience: {
            "Product Marketing Manager Intern": "Okta",
            "Product Marketing Intern": "Simplify",
            "ETF & Content Marketing Intern": "Invesco",
            "Social Media Marketing Manager": "MPH",
            "Marketing Intern":"Snoice",
            "Marketing Intern":"Koplus International",
            "Sales Assistant Intern":"Smith & Associates",
        },
        campusInvolvements: {
            "Marketing Association":"President, Finance Director",
            "Product Association": "Product Marketing Manager Fellow"
        },
        interests: ["Basketball", "Volleyball", "Soccer", "Beaches", "Hikes"],
        whyLPN: "LPN has brought me not oLPN has given me not only a clear sight into what I want to do for my career, it also gave me life long friends that has shaped my college years. I'm appreciative of the people in LPN because of their willingness to help and inspire those around you to become a better person. If it weren't for their encouragement and constant motivation, I wouldn't be who I am today. nly knowledge on professionalism, it also gave me friends that I know I can always rely on. I definitely wouldn’t be where I am without LPN!",
        img: require('../../../../assets/actives/brothers/Ruby_Ko.jpg'),
    },
    Qui_Hoang: {
        linkedIn: "https://www.linkedin.com/in/quihoang/",
        homeTown: "Oakland, CA",
        year: "4th",
        class: "Sigma",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Accounting",
        practice: "Project Management",
        pronouns: "She/Her/Hers",
        experience: {"Project Delivery Intern":"Critical Mass", "Risk Advisory Intern":"Grant Thornton", "Career Center Student Assistant":"Paul Merage School of Business","Brand Ambassador":"GIST", "Studnet Ambassador":"Surgent CPA Review","Rotational Intern":"Grant Thorton"},
        campusInvolvements: {"Chief Talent Officer":"Lambda Phi Nu", "Executive Director" : "Antleader Mentorship Program", "Recruiter":"Lambda Phi Nu","Administrative Intern Program Coordinator":"Center for Student Leadership", "Career Center Student Assistant":"Paul Merage School of Business", "Interal Vice President":"Accounting Association"},
        interests: ["Travel", "Trying new food spots", "Hiking", "Board/card games", "Anime", "Spending time with friends", "R&B", "Hiphop", "Artsy hobbies"],
        whyLPN: "I chose LPN because of its tight-knit, supportive community. I've met brothers here who constantly inspire and encourage me to step out of my comfort zone, both professionally and personally. I've learned so much about myself and grown a lot in the time that I've been here, and it's all thanks to the supportive people I've met in LPN.",
        img: require('../../../../assets/actives/brothers/Qui_Hoang.jpg'),
    },
    // Tau
    Alex_Zhou: {
        linkedIn: "https://www.linkedin.com/in/zhouaz/",
        homeTown: "Southlake, TX",
        year: "2nd",
        class: "Tau",
        major: "Computer Science",
        minor: "",
        emphasis: "",
        practice: "Software Engineering",
        pronouns: "He/Him/His",
        experience: {
            "Riteline USA":"Software Engineering Intern"
        },
        campusInvolvements: {
            "VP of Internal Affairs":"Management Information Student Society", 
            "Mentee":"Management Information Student Society",
            "Member":"Chinese Association"
        },
        interests: ["Golfing", "Video Games", "Reading", "Traveling", "Sea Otters"],
        whyLPN: "I joined LPN to connect with like-minded individuals, gain valuable networking opportunities, and cultivate the essential skills needed for success in the competitive professional world.",
        img: require('../../../../assets/actives/brothers/Alex_Zhou.jpg')
    },
    Brandyn_Huynh: {
        linkedIn: "https://www.linkedin.com/in/brandyn-le-huynh/",
        homeTown: "Milpitas, CA",
        year: "4th",
        class: "Tau",
        major: "Business Administration",
        minor: "",
        emphasis: "Marketing and Organizational Management",
        practice: "Project Management",
        pronouns: "He/Him/His",
        experience: {"Area Manager Intern":"Amazon", "Shift Lead":"Tea Doraku", "Founder": "B's Premium Teas", "English Second Language Tutor": "San Jose City College"},
        campusInvolvements: {"Member Relations Director":"Marketing Association", "Mentor": "Management Information Student Society", "Social Impact Director":"Lambda Phi Nu", "Co-Company Relations Director":"Marketing Association"},
        interests: ["Evening runs", "Biking", "Concerts", "Painting", "Thrifting", "Trying different food places", "Learning different instruments" ],
        whyLPN: "Coming in as a transfer, I wanted to get involved as much as possible and seek out any opportunities for personal growth and professional development. I still had a lot of learning about the different industries within the business realm and was still determining what I wanted to pursue career-wise. However, once joining LPN, I had the chance to be surrounded by a community of unique and motivated individuals who supported me by helping me figure out my career goals and also allowing me to learn from their experiences personally and professionally. LPN is a tight-knit community that fosters strong brotherhood and has given me a community of people I know I can always depend on and draw inspiration from.",
        img: require('../../../../assets/actives/brothers/Brandyn_Huynh.jpg')
    },
    Denise_Wei: {
        linkedIn: "https://www.linkedin.com/in/denise-wei/",
        homeTown: "San Francisco, CA",
        year: "4th",
        class: "Tau",
        major: "Business Administration, Data Science",
        minor: "",
        emphasis: "Accounting",
        practice: "Audit",
        pronouns: "She/Her/Hers",
        experience: {"Audit Intern":"PwC", "Junior Golf Coach": "First Tee of San Francisco"},
        campusInvolvements: {"External Vice President":"Accounting Association", "Mentor":"AALDP","Committee Board Officer":"Accounting Association", "Finance Associate":"Lambda Phi Nu"},
        interests: ["Golf", "Snowboarding", "Card games", "Trying new extreme sports" ],
        whyLPN: "I wanted to immerse myself in a community of driven and supportive individuals. Everyone at LPN carries themselves with confidence and are always willing to help out, even those outside of the fraternity. Seeing this through rush was what ultimately led me to join LPN. Through LPN, I have been able to form meaningful relationships with both my Pledge Class and Active Brothers, and I can always rely on them for personal and professional help. These connections are unique in that I know they will extend beyond college.",
        img: require('../../../../assets/actives/brothers/Denise_Wei.jpg')
    },
    Evan_Choe: {
        linkedIn: "https://www.linkedin.com/in/evan-c-choe/",
        homeTown: "Irvine, CA",
        year: "3rd",
        class: "Tau",
        major: "Business Administration",
        minor: "Computer Science",
        emphasis: "Finance",
        practice: "Consulting, Product Management",
        pronouns: "He/Him/His",
        experience: {
            "Product Manager": "Questify",
            "Co-Founder":"Echo Speech & Debate"
        },
        campusInvolvements: {
            "Executive Vice President, VP of External Relations":"Management Information Student Society",
            "Representative":"Merage Undergraduate Student Association",
        },
        interests: ["History (Age of Enlightenment)", "K-Pop", "Classics", "Writing", "Reading", "History & Philosophy", "Hockey", "Soccer", "Video Games", "Geography", "MBTI" ],
        whyLPN: "I have never grown faster within such a short period of time as I have here in LPN. As long as I put in the effort, I knew I was improving every step of the way. More importantly, there were rarely times I experienced something alone. In common struggle and common joy, I could not imagine myself in LPN without those who were there to support me.",
        img: require('../../../../assets/actives/brothers/Evan_Choe.jpg')
    },
    Grace_Xu: {
        linkedIn: "https://www.linkedin.com/in/grace-xuuu/",
        homeTown: "Birmingham, AL",
        year: "3rd",
        class: "Tau",
        major: "Business Economics",
        minor: "Digital Arts",
        emphasis: "",
        practice: "Product Marketing or Management",
        pronouns: "She/Her/Hers",
        experience: {
            "Marketing Intern ":"Scale Agent",
            "Marketing Intern":"Dolmazzi Group",
            "Desk Assistant":"Langson Library",
            "Music Radio Host":"KUCI",
            "Music Librarian":"KUCI"
        },
        campusInvolvements: {
            "VP of Marketing":"UBA",
            "Administrative Director": "MA",
            "Radio Host": "KUCI 88.9",
            "Graphic Design Intern" :"New University",
            "Audio Intern":"KUCI"
        },
        interests: ["Window shopping", "Gaming", "Drawing", "Making Spotify playlists & finding music", "Exploring cafes", "Cinematography"],
        whyLPN: "It was a perfect blend of an genuine and supportive tight-knit community and professional development!",
        img: require('../../../../assets/actives/brothers/Grace_Xu.jpg')
    },
    Madhu_Sharma: {
        linkedIn: "https://www.linkedin.com/in/madhuxsharma/",
        homeTown: "Plano, TX",
        year: "3rd",
        class: "Tau",
        major: "Computer Science",
        minor: "",
        emphasis: "",
        practice: "Software Engineering",
        pronouns: "He/Him/His",
        experience: {"Co-Founder":"Bonsai","Software Development Engineer Intern":"Amazon","Software Developer":"Delta System & Software, Inc.", "Instructor":"iCode"},
        campusInvolvements: {"Webmaster": "Lambda Phi Nu", "Technical Lead":"Commit the Change","Information Associate":"Lambda Phi Nu"},
        interests: ["Hanging out with friends", "Trying out new foods", "Videogames", "Music", "K-Pop Dance", "Volleyball",  "Weightlifting" ],
        whyLPN: "I initially wanted to join a business fraternity because I value creating life-long relationships with others. Not the type of friends you only hang out with, but also the ones that will push you to be the best version of yourself. Upon hearing about Lambda Phi Nu, I connected with the motto: 'Grow Forward. Give Back.' Throughout my life, I have had amazing people help me grow forward, and I enjoy helping people, especially those who used to be in my position. Since joining LPN, I have enjoyed every interaction I have with the Active Brothers, and I look forward to spending more time with them in the upcoming years.",
        img: require('../../../../assets/actives/brothers/Madhu_Sharma.jpg')
    },
    Rainer_Setiawan: {
        linkedIn: "https://www.linkedin.com/in/rainer-setiawan/",
        homeTown: "Chino Hills, CA",
        year: "4th",
        class: "Tau",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Marketing",
        practice: "Product Design / Marketing",
        pronouns: "He/Him/His",
        experience: {
            "Product Design Intern":"Profound", 
            "Supervisor":"Tastea",
            "Assistant Tennis Coach":"Woodbridge High School"},
        campusInvolvements: {
            "Co-Vice President of Marketing":"Undergraduate Business Association",
            "UX/UI Designer":"Design@UCI",
            "TCohort":"LEADS Program",
        },
        interests: ["Video Games", "Basketball", "Tennis", "Travel", "Manga"],
        whyLPN: "I rushed LPN wanting professional help, and I have now received that and so much more. More importantly, I am now in a community that I would have never expected I would be in at UCI. LPN feels like a big group of friends and everyone motivates each other to be the best version of themselves. I have made so many memories with LPN and I am looking forward to making many more.",
        img: require('../../../../assets/actives/brothers/Rainer_Setiawan.jpg')
    },
    Tannvi_Banerjee: {
        linkedIn: "https://www.linkedin.com/in/tannvi-banerjee/",
        homeTown: "Fremont, CA",
        year: "3rd",
        class: "Tau",
        major: "Computer Science",
        minor: "",
        emphasis: "",
        practice: "Software Engineering",
        pronouns: "She/Her/Hers",
        experience: {
            "Incoming Software Engineer Intern": "Meta",
            "Software Engineer Intern": "Apsy",
            "Software Engineer Intern": "Profound",
            "Computational Economics Researcher":"New York University",
            "Data Science Intern":"Stanford Daily",
            "Computational Biology Researcher":"ASDRP Research Labs"
        },
        campusInvolvements: {
            "President, VP of Fellowship, Software Engineering Fellow" : "Product Association",
            "VP of Professional Development" : "Management Information Student Society",
            "Software Developer":"Commit the Change"
        },
        interests: ["Baking", "Traveling", "Taking Film Pictures", "Fashion", "Concerts", "Food", "Crossword Puzzles", "Philosophy", "Typeracing" ],
        whyLPN: "I joined LPN as a first year to find a supportive, tight-knit community that I could grow alongside throughout my time in college :)",
        img: require('../../../../assets/actives/brothers/Tannvi_Banerjee.jpg')
    },
    Teresa_Chu: {
        linkedIn: "https://www.linkedin.com/in/teresa-chu56/",
        homeTown: "Taipei, Taiwan",
        year: "3rd",
        class: "Tau",
        major: "Business Administration",
        minor: "",
        emphasis: "Finance, Information Systems",
        practice: "Asset Management, Corporate Banking, Equity Research",
        pronouns: "She/Her/Hers",
        experience: {
            "Corporate Banking Summer Analyst": "Citi",
            "Lead Student Assistant" : "UCI Paul Merage Project Management Office",
            "Global Investment Strategy & Technology Equity Research Intern":"Taishin Financial Holdings"
        },
        campusInvolvements: {
            "President (Founding Chapter Team)":"Scholars of Finance", 
            "Co-President":"Financial Literacy Association",
            "Mentor, Scholar Committee Member, Mentee":"International Peer Group Association",
            "Intern, Mentee":"Undergraduate Business Association"
        },
        interests: ["Major Foodie", "Cooking", "Traveling", "Skiing", "Hiking", "Swimming", "Gymming", "Skincare", "Hanging out with friends" ],
        whyLPN: "LPN is a place where I can 'Grow Forward. Give Back' to the community with a group of driven people. I am grateful that I have learned and been helped a lot during my time as a mentee in my freshmen year. Therefore, I am eager to advocate the virtuous cycle of mentorship, as I am constantly learning from others and offering help to others. I love how this family empowers people to become the best version of themselves.",
        img: require('../../../../assets/actives/brothers/Teresa_Chu.jpg')
    },

    // Upsilon

    Alie_Li: {
        linkedIn: "https://www.linkedin.com/in/alison-li1/",
        homeTown: "Castro Valley, CA",
        year: "3rd",
        class: "Upsilon",
        major: "Political Science",
        minor: "",
        emphasis: "",
        practice: "Marketing",
        pronouns: "She/Her/Hers",
        experience: {
            "Partner Marketing Intern": "Amazon",
            "Database Marketing Intern": "Integral Consulting Inc."
        },
        campusInvolvements: {
            "Marketing Association" : "Finance Director",
            "TEDxUCIrvine" : "Social/Content Media Marketing Team"
        },
        interests: ["Golf", "Sewing", "Getting coffee with friends", "Crocheting", "Gel Nails"],
        whyLPN: "I love LPN because I highly value the great relationships and sense of community I have found within the fraternity.",
        img: require('../../../../assets/actives/brothers/Alie_Li.jpg')
    },

    Deborah_Kim: {
        linkedIn: "https://www.linkedin.com/in/deborahjkim",
        homeTown: "Anaheim, CA",
        year: "2nd",
        class: "Upsilon",
        major: "Business Administration",
        minor: "",
        emphasis: "Marketing",
        practice: "Marketing",
        pronouns: "She/Her/Hers",
        experience: {
            "Marketing Intern": "Tarsus Pharmaceuticals, Inc.",
            "Mentor": "Anaheim Innovative Mentoring Experience",
            "Elevate to Innovate Extern": "Accenture",
            "Peer Educator & Peer Educator Lead": "Student Success Initiatives Office",
            "Barista": "Basilur"
        },
        campusInvolvements: {
            "Mentee" : "Undergraduate Business Association",
            "VP of Marketing" : "Human Resources Management Association",
            "Broadcast Crew Member" : "UC Irvine Athletics" 
        },
        interests: ["Eating", "Photography", "Karaoke", "Watching K-Dramas", "Shopping"],
        whyLPN: "LPN and the community I found within it motivate me to be the best version of myself both personally and professionally. I came into UCI with an eagerness to grow but no sense of direction on how. Through a season of discouragement and confusion trying to adapt to a new environment, all the brothers have been so incredibly supportive and giving. LPN motivates me to keep striving for success and give back to a community I care deeply about.",
        img: require('../../../../assets/actives/brothers/Deborah_Kim.jpg')
    },

    Dustin_Wong: {
        linkedIn: "https://www.linkedin.com/in/dustindwong07/",
        homeTown: "San Francisco, CA",
        year: "4th",
        class: "Upsilon",
        major: "Business Economics",
        minor: "Management",
        emphasis: "",
        practice: "Consulting/Project Management",
        pronouns: "He/Him",
        experience: {"Business Operations Intern":"Sparrow", "Site Manager": "E-Commerce", "Sales Intern" : "Business Analyst for Hirect"},
        campusInvolvements: {"Finance Associate" : "Lambda Phi Nu" },
        interests: ["Basketball", "Gym", "Swimming", "Kickboxing", "Fashion", "Video Games", "K-dramas", "Rap/R&B"],
        whyLPN: "At first, I wanted to find professional advice for my future career. However, as I met everyone in LPN and gained more experience, I was able to develop something valuable:brotherhood.",
        img: require('../../../../assets/actives/brothers/Dustin_Wong.jpg')
    },

    Joseph_Tom: {
        linkedIn: "https://www.linkedin.com/in/josephjtom/",
        homeTown: "San Mateo, CA",
        year: "4th",
        class: "Upsilon",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Information Systems",
        practice: "Product Management, Product Design",
        pronouns: "He/Him",
        experience: {
            "Product Development Intern": "Capital One",
            "Interactive Design Intern": "FormFactor",
            "Product Design Mentee" : "Meta", 
            "Marketing and Social Media Coordinator" : "UCI SOAR Center"
        },
        campusInvolvements: {
            "Executive Vice President" : "Undergraduate Business Association",
            "Product Design Lead" : "Product Association"
        },
        interests: ["Drawing", "Anime", "Eating", "Skincare"],
        whyLPN: "I wanted to have a supportive community of like-minded people who understood my goals and ambitions. Everyone in LPN is very willing to help you with anything personally and professionally!",
        img: require('../../../../assets/actives/brothers/Joseph_Tom.jpg')
    },


    Puneet_Singh: {
        linkedIn: "https://linkedin.com/in/puneet-singh2/",
        homeTown: "Irvine, CA",
        year: "2nd",
        class: "Upsilon",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Finance",
        practice: "Corporate Finance/Banking",
        pronouns: "She/Her/Hers",
        experience: {
            "Finance Due Diligence Intern": "PwC",
            "Corporate Finance Intern": "Nordstrom",
            "Summer Commercial Banking Intern": "1st Century Bank"
        },
        campusInvolvements: {
            "VP of Finance": "Irvine Investment and Trading Group", 
            "VP of Finance": "Sikh Student Association", 
            "Associate": "Merage Undergraduate Student Association", 
            "VP of Professional Development": "Undergraduate Business Association",
        },
        interests: ["Singing", "Basketball", "Running", "Songwriting", "Thrifting", "Going to the beach", "Eating"],
        whyLPN: "Joining as a first-year, LPN has given me the unique opportunity to develop professionally at such an early stage in my career. I feel that the network and connections that I have developed in this fraternity have helped me in my professional pursuits, and also to make friendships that I hope will last a lifetime. LPN is truly a family and I love being a part of it.",
        img: require('../../../../assets/actives/brothers/Puneet_Singh.jpg')
    },

    Rayyaan_Majid: {
        linkedIn: "https://www.linkedin.com/in/rayyaan-majid/",
        homeTown: "Little Rock, AR",
        year: "2nd",
        class: "Upsilon",
        major: "Political Science",
        minor: "",
        emphasis: "",
        practice: "Pre-Law (Corporate Law)",
        pronouns: "He/Him",
        experience: {"Product Design Mentee" : "Meta",  "Marketing and Social Media Coordinator" : "UCI SOAR Center" },
        campusInvolvements: {"Fall Recruiter" : "Lambda Phi Nu"},
        interests: ["Playing soccer with friends", "Thrifting", "Finding new quotes", "Playing online video games"],
        whyLPN: "I chose LPN to find a community that would always encourage me to be the best version of myself and also to find a group of people I could fall back and rely on.",
        img: require('../../../../assets/actives/brothers/Rayyaan_Majid.jpg')
    },

    Sophia_Tran: {
        linkedIn: "https://www.linkedin.com/in/sophiatran118/",
        homeTown: "San Jose, CA",
        year: "4th",
        class: "Upsilon",
        major: "Computer Sciencen",
        minor: "",
        emphasis: "",
        practice: "Software Engineering",
        pronouns: "She/Her/Hers",
        experience: {"Software Engineering Intern" : "Dassault Systemes"},
        campusInvolvements: {"Internal Operations Officer" : "TEDxUCIrvine (2021-2022)"},
        interests: ["Music", "Food", "Hiking", "Fashion"],
        whyLPN: "LPN gave me a community of people to support me both personally and professionally. I am genuinely thankful for all the people I've met and the opportunities I've been given because of LPN.",
        img: require('../../../../assets/actives/brothers/Sophia_Tran.jpg')
    },


    Zoe_Lui: {
        linkedIn: "https://www.linkedin.com/in/zoejlui/",
        homeTown: "Arcadia, CA",
        year: "3rd",
        class: "Upsilon",
        major: "Business Econmics",
        minor: "",
        emphasis: "",
        practice: "Marketing and Management",
        pronouns: "She/Her/Hers",
        experience: {
            "Marketing Intern": "JT Aesthetics & Co"
        },
        campusInvolvements: {
            "Marketing Association": "VP of Internal Affairs & Member Relations",
            "AntLeader Mentorship Program": "Media and Publicity Coordinator"
        },
        interests: ["Dance", "Traveling", "Baking", "Food Adventures","Concerts", "K-dramas"],
        whyLPN: "LPN has made and continues to make a profoundly positive impact on my life, both professionally and personally. I truly cherish the sense of community and brotherhood :')",
        img: require('../../../../assets/actives/brothers/Zoe_Lui.jpg')
    },

    // Chi

    Aidan_Jayakumar: {
        linkedIn: "https://www.linkedin.com/in/aidan-jayakumar/",
        homeTown: "Irvine, CA",
        year: "2nd",
        class: "Chi",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Finance",
        practice: "Consulting",
        pronouns: "He/Him/His",
        experience: {
            "Financial Advisory Intern": "Capstone Partners",
            "Law Clerk":"Carlson & Jayakumar LLP",
        },
        campusInvolvements: {
            "180 Degrees": "Associate Consultant",
            "Management Information Student Society": "VP of Finance",
            "Undergraduate Business Association": "Intern"
        },
        interests: ["Soccer", "Football", "Golf", "Skiing", "Late Night Drives", "Eating New Foods", "Traveling"],
        whyLPN: "As a first year student, I felt lost before I joined LPN. Being a member of LPN has not only provided me with the professional resources and development I was looking for but also a community of driven individuals that I get to have fun with. I couldn't imagine what my college experience would entail without joining LPN.",
        img: require('../../../../assets/actives/brothers/Aidan_Jayakumar.jpg')
    },

    Annie_Sreshthabutra: {
        linkedIn: "https://www.linkedin.com/in/anniesresh/",
        homeTown: "Bangkok, Thailand",
        year: "2nd",
        class: "Chi",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "Marketing",
        practice: "Marketing",
        pronouns: "She/Her/Hers",
        experience: {
            "Strategic Planning Intern": "Hakuhodo International",
            "Content Creator Intern": "Copperwired Public Co., Ltd",
            "Digital Marketing Intern": "One Publique"
        },
        campusInvolvements: {
            "Marketing Association": "VP of External Affairs, Marketing Director",
            "Undergraduate Business Association": "Intern",
        },
        interests: ["Reading", "Taking Pictures", "Making Art", "Journaling", "Trying New Food Spots", "Listening to Music"],
        whyLPN: "Joining LPN has helped me find a home away from home. In just a few months, I have made countless memories with some of the best people I have ever met and grown so much more than I ever thought I could. In LPN, I have found a group of individuals who inspire me to be the best version of myself everyday, and I am so grateful for this community.",
        img: require('../../../../assets/actives/brothers/Annie_S.jpg')
    },
    
    Catherine_Tran: {
        linkedIn: "https://www.linkedin.com/in/catherinetran683/",
        homeTown: "Oxnard, CA",
        year: "4th",
        class: "Chi",
        major: "Business Economics",
        minor: "Digital Information Systems",
        emphasis: "",
        practice: "Finance",
        pronouns: "she/her",
        experience: {
            "Finance/Accounting Intern": "TruStage",
            "Finance and Accounting Intern": "Gold Coast Transit District"
        },
        campusInvolvements: {
            "MAISS": "Mentor",
            "Finance Literacy Association": "Director of Finance & Internal"
        },
        interests: ["Reading", "Watching movies and TV shows", "Thrifting", "Going out with friends", "Exploring new food spots"],
        whyLPN: "As a third-year transfer, I wanted to become more involved and find a community of people I could grow with. Within a short period of time, I have become extremely close with everyone in LPN and have grown both professionally and personally. LPN has provided me with some of my greatest friends and mentors. After joining LPN, I have found the community and people who will forever influence my life and always be my greatest support system.",
        img: require('../../../../assets/actives/brothers/Catherine_Tran.jpg')
    },

    Elise_Naddell: {
        linkedIn: "https://www.linkedin.com/in/elise-naddell/",
        homeTown: "Palo Alto, CA",
        year: "3rd",
        class: "Chi",
        major: "Environmental Science and Policy",
        minor: "Urban Studies",
        emphasis: "",
        practice: "Consulting, Marketing, Business Development",
        pronouns: "She/Her",
        experience: {
            "Marketing Intern": "BYD",
            "Commercial Real Estate Intern": "Project Destined",
            "Recruiter, Sourcing Intern": "ROCS Global Inc.",
            "Teacher Assistant": "GSEP Program",
        },
        campusInvolvements: {
            "UCI Solar Car": "Business Developer",
            "UCI Dragon Boat": "Land Coach"
        },
        interests: ["Paddling", "Weight Lifting", "Tennis", "Drawing", "Movies", "Trying New Things", "Outdoor Activities", "Fashion"],
        whyLPN: "I came to LPN to get a glimpse of the business world and found myself in an amazing community of driven, hard-working individuals. Everyone in LPN truly strives for the best in whatever they pursue, and everyone here inspires me to keep pushing beyond what I had expected for myself. I am so thankful to be a part of this friendly and inclusive environment that has allowed me to grow so much in such little time.",
        img: require('../../../../assets/actives/brothers/Elise_Nadell.jpg')
    },

    Ethan_Tung: {
        linkedIn: "https://www.linkedin.com/in/ethantung",
        homeTown: "Kaohsiung, Taiwan",
        year: "2nd",
        class: "Chi",
        major: "Business Administration & Informatics",
        minor: "",
        emphasis: "Finance, Healthcare",
        practice: "Financial Technology, Risk Management",
        pronouns: "He/Him",
        experience: {
            "Incoming Summer Analyst (Global Investment Research Division)": "Goldman Sachs",
            "Information Technology & Analytics Researcher": "Accenture",
            "Business Analyst, Marketing Specialist": "Computing ReApplied",
            "Investment Banking Corporate Finance Program Participant": "J.P Morgan",
            "Investment Banking Series": "Deutsche Bank"
        },
        campusInvolvements: {
            "Finance Literacy Association": "Director of External Relations",
            "Management Information Student Society": "Finance Intern",
            "Merage Undergraduate Student Association": "Representative",
            "TEDxUCIrvine": "Finance Team"
        },
        interests: ["Pondering", "Fashion", "Driving", "Basketball", "Volleyball", "Cooking", "Eating", "Guitar"],
        whyLPN: "LPN reminds me every day how family can also refer to people who are not related by blood to me.",
        img: require('../../../../assets/actives/brothers/Ethan_Tung.jpg')
    },

    Gabriel_Villamariona: {
        linkedIn: "https://www.linkedin.com/in/gabrielvillamariona/",
        homeTown: "San Ramon, CA",
        year: "4th",
        class: "Chi",
        major: "Business Administration",
        minor: "",
        emphasis: "Finance",
        practice: "Finance",
        pronouns: "He/Him",
        experience: {
            "Financial Planning & Analysis Intern": "Advanced Sterilization Products",
            "Esports Athlete": ["Cloud9 Esports", "Tribe Gaming"]
        },
        campusInvolvements: {
            "Scholars of Finance": "VP of Leadership Development"
        },
        interests: ["Esports", "Video Games", "Skiing", "Jet-Skiing", "Amusement Parks", "Tech"],
        whyLPN: "As a third-year transfer student, finding a community of driven individuals was a priority. With Lambda Phi Nu, I could push myself and grow more in seven weeks than I thought possible.",
        img: require('../../../../assets/actives/brothers/Gabriel_V.jpg')
    },

    Jessica_Nguyen: {
        linkedIn: "https://www.linkedin.com/in/jesstnguyen03/",
        homeTown: "Santa Clara, CA",
        year: "4th",
        class: "Chi",
        major: "Economics",
        minor: "",
        emphasis: "",
        practice: "Accounting",
        pronouns: "She/Her/Hers",
        experience: {
            "Audit and Assurance Intern": "Ernst & Young",
            "Brand Ambassador": "American Eagle Outfitters, Inc.",
            "Student Teaching Assistant": "Kumon Learning Center"
        },
        campusInvolvements: {
            "MAISS": "Mentor",
            "Accounting Association": "Committee Board Officer, Mentee",
            "Vietnamese Student Association": "Member"
        },
        interests: ["Painting", "Shopping", "Taking walks", "Going to the beach", "Trying restaurants/cafes", "Late-night drives"],
        whyLPN: "As a transfer student coming to UC Irvine, I wanted to find a community of people that I can spend my time with as well as jumpstart my professional career. I decided to join LPN because of how welcoming the active brothers were to me. I feel comfortable enough reaching out to the actives and alumni about anything regarding my career, school, or personal responsibilities. Because of this, I consider LPN as a family that I can always depend on.",
        img: require('../../../../assets/actives/brothers/Jessica_Nguyen.jpg')
    },

    Kate_Arnold: {
        linkedIn: "https://www.linkedin.com/in/kateaarnold05/",
        homeTown: "",
        year: "4th",
        class: "Chi",
        major: "Business Administration",
        minor: "",
        emphasis: "Organization and Management",
        practice: "Operations and Management",
        pronouns: "She/Her",
        experience: {
            "Shift Supervisor": "Starbucks",
            "Team Member": "Jamba"
        },
        campusInvolvements: {},
        interests: ["Concerts", "Lifting Weights", "Going to The Beach", "Traveling to New Countries"],
        whyLPN: "As a transfer student, one of the reasons I chose to go to UC Irvine was because of its business fraternities. LPN resonated with me the most and I found my people at this campus. Even in my little time here, I have already created such long-lasting friendships and unforgettable memories that I will always hold close. The idea of 'grow forward, give back' is something that I truly stand by. This community constantly does what is best for each individual to help everyone be their best. I have learned many professional and life skills that I will carry with me even after I graduate.",
        img: require('../../../../assets/actives/brothers/Kate_Arnold.jpg')
    },

    Lana_Locquiao_Noguera: {
        linkedIn: "https://www.linkedin.com/in/lanalocquiaonoguera/",
        homeTown: "San Diego, CA",
        year: "3rd",
        class: "Chi",
        major: "Business Economics",
        minor: "International Studies",
        emphasis: "",
        practice: ["Commercial Real Estate", "Project Management", "Real Estate"],
        pronouns: "She/Her/Hers",
        experience: {
            "Commercial Real Estate Intern": "Project Destined",
            "Investment Brokerage Intern": "Marcus & Millichap",
            "Capital Markets Intern": "JLL",
        },
        campusInvolvements: {
            "Undergraduate Business Association": "VP of Company Relations",
            "Kababayan": "Business & Marketing Officer",
            "Real Estate Association": "External Affairs Intern"
        },
        interests: ["Basketball", "Thrifting", "Singing", "Asian Dramas", "Reading"],
        whyLPN: "I chose Lambda Phi Nu because not only did it provide me with extremely insightful guidance within the professional world, but it also provided me with a second family and a second home. To me, Lambda Phi Nu means embodying who you are and having the ability to accomplish anything you put your mind to. It also means that whatever obstacles you face, you are not alone and are surrounded by driven individuals who will support you along the way. I have grown exponentially with Lambda Phi Nu, and I am so grateful for the wonderful and enlightening experiences that this fraternity has given me. Lambda Phi Nu has also helped me create long-lasting relationships and deep connections that I will forever cherish. Lambda Phi Nu opened so many doors to new opportunities and I am confident in having a successful and happy future because of Lambda Phi Nu.",
        img: require('../../../../assets/actives/brothers/Lana_Noguera.jpg')
    },

    Mason_Chen: {
        linkedIn: "https://www.linkedin.com/in/mason-ray-chen/",
        homeTown: "Oakland, CA",
        year: "2nd",
        class: "Chi",
        major: "Business Administration",
        minor: "",
        emphasis: "Finance",
        practice: "Financial Advising",
        pronouns: "He/Him",
        experience: {
            "Recreational Leader": "City of Emeryville"
        },
        campusInvolvements: {
            "Finance Literacy Association": "Director of External Relations",
            "Merage Undergraduate Student Association": "Representative"
        },
        interests: ["Lacrosse", "Dancing", "Edits", "Basketball", "Golf", "Traveling"],
        whyLPN: "LPN served as more than just a means to develop professionally; in a short amount of time it has enabled me to grow in all aspects of my life by pushing me and introducing me to a new group of ambitious and supportive people that are always there for me.",
        img: require('../../../../assets/actives/brothers/Mason_Chen.jpg')
    },

    Skyler_Chang: {
        linkedIn: "https://www.linkedin.com/in/skylerjchang/",
        homeTown: "Raleigh, NC",
        year: "3rd",
        class: "Chi",
        major: "Psychological Science",
        minor: "Management",
        emphasis: "",
        practice: "Human Resources, Management, HRBP, HRPM",
        pronouns: "He/him",
        experience: {
            "HR Intern": "First American Financial Corporation",
            "HR Intern": "Noona's Noonchi",
            "Set & Project Manager": "Warner Records",
            "Manager": "Seol Grille",
            "Artist Manager": "Self Employed",
            "Tennis and Soccer Private Coach": "Self Employed",
        },
        campusInvolvements: {
            "Management Information Student Society": "Intern",
            "Undergraduate/Undeclared Mentorship Program": "Mentor",
            "Tennis Club": "Officer",
            "Human Resources Management Association": "Intern"
        },
        interests: ["Tennis", "Soccer", "Drawing", "Reading", "Guitar", "Ukulele", "Sleeping", "Working Out", "Photography", "Boxing", "Eating"],
        whyLPN: "Everyone in LPN is unique and diverse in their own ways. Finding a community that is so driven and ambitious to be better than they were yesterday motivates me to pursue my goals and passions with confidence.",
        img: require('../../../../assets/actives/brothers/Skyler_Chang.jpg')
    },
    
    Thomas_Lin: {
        linkedIn: "https://www.linkedin.com/in/thomas-lin21/",
        homeTown: "Pleasanton, CA",
        year: "4th",
        class: "Chi",
        major: "Business Administration",
        minor: "",
        emphasis: "Finance",
        practice: "Accounting",
        pronouns: "He/Him",
        experience: {
            "Ernst & Young": "Incoming Audit and Assurance Associate",
            "Cactus & Pearl": "Finance & Accounting Intern",
            "Lifetime Activities": "Assistant Manager, Assistant Tennis Coach, Customer Service Representative",
        },
        campusInvolvements: {
            "Finance Literacy Association":"Executive Vice President",
            "Undergraduate Business Association": "VP of Company Relations",
            "Merage Undergraduate Student Association": "VP of Academic Affairs, Mentor"
        },
        interests: ["Basketball", "Formula 1", "Andrea Bocelli", "Interstellar", "Baking", "Hanging out with Chis", "Working Out"],
        whyLPN: "As a 3rd year transfer, I did not know anybody coming into UC Irvine. Additionally, I was unclear about my career path. One of my main goals coming in was to build my network and develop myself professionally. I did some research while attending events for Merage Undergraduate Student Association, Management Information Student Society, UFA, and other organizations and I discovered business fraternities. After discovering the different business fraternities, I rushed for Delta Sigma Pi and Lambda Phi Nu. I found that Lambda Phi Nu was the right fit for me. Everyone was extremely welcoming, and genuine when I talked to them during rush and it made me feel included. Additionally, during the Week 0 social in Aldrich Park when I first met some of the Actives, they invited us to an after social which made me feel like I was already apart of the brotherhood. Aside from that, during the Business Fraternity Panel held by Merage Undergraduate Student Association I had learned about the different professional benefits that Lambda Phi Nu could offer, and that enticed me to join. Overall, Lambda Phi Nu embodies the epitome of tight bonds and professionalism.",
        img: require('../../../../assets/actives/brothers/Thomas_Lin.jpg')
    },
    
    Tiffany_Chien: {
        linkedIn: "https://www.linkedin.com/in/chient3/",
        homeTown: "Irvine, CA",
        year: "2nd",
        class: "Chi",
        major: "Business Administration",
        minor: "Informatics",
        emphasis: ["Marketing", "Information Systems"],
        practice: "Product Management",
        pronouns: "she/her",
        experience: {
            "Engagement Marketing Intern": "Siemens",
            "Marketing & Sales Intern": "Niagara Bottling",
            "Accelerate Marketing & Communications Fellow": "IBM",
            "Consulting Mentorship Program Participant": "Deloitte",
            "Business Development Intern": "Colere Brands",
            "Social Media & Marketing Intern": "Building-U",
        },
        campusInvolvements: {
            "ASUCI - Internal Vice President Office": "Outreach Intern",
            "Merage Undergraduate Student Association": "VP of Technology, Freshman Representative"
        },
        interests: ["Cafe Hopping", "Karaoke", "Exploring New Places", "Sketching", "Reading", "Stargazing"],
        whyLPN: "I joined LPN because every person was so friendly and welcoming. LPN's warm, close-knit, and driven community resonated with what I wanted out of my college experience. I felt like I could achieve my goals within the business world and also make friends and have fun along the way in LPN. Professionally, LPN has been a catalyst of change for me. From developing and pushing me beyond my personal and professional comfort zone, LPN has given me experiences my high school self could not even imagine.",
        img: require('../../../../assets/actives/brothers/Tiffany_Chien.jpg')
    },
    
    Timothy_Sih: {
        linkedIn: "https://www.linkedin.com/in/timothysih",
        homeTown: "Taipei, Taiwan",
        year: "2nd",
        class: "Chi",
        major: "Business Administration",
        minor: "",
        emphasis: "Accounting",
        practice: "Audit",
        pronouns: "He/Him/His",
        experience: {
            "Financial Services Assistant": "UCI Physical Sciences",
            "Incoming Audit Intern": "PwC",
            "Accounting Intern": "Chen & Fan Accountancy Corp.",
            "Intern": "Special Olympics East Asia"
        },
        campusInvolvements: {
            "Undergraduate Business Association": "VP of Company Relations",
            "Accounting Association": "Committee Board Officer",
            "Merage Undergraduate Student Association": "Representative"
        },
        interests: ["Baseball", "Grubbing", "Video Games", "Watching Sports", "Traveling", "Hiking"],
        whyLPN: "Coming to UCI as an international student, joining LPN has allowed me to find a second family. Joining LPN has helped me grow more than any other time in my life, and inspires me to be better every single day. I'm thankful that I have a community that has my back and I'm glad I can call these people my brothers for the rest of my life.",
        img: require('../../../../assets/actives/brothers/Timothy_Sih.jpg')
    },
    
    // Psi

    Ben_Nguyen: {
        linkedIn: "https://www.linkedin.com/in/ben-nguyenn/",
        homeTown: "Anaheim, CA",
        year: "2nd",
        class: "Psi",
        major: "Business Administration",
        minor: "",
        emphasis: "",
        practice: "Accounting",
        pronouns: "He/Him",
        experience: {
        },
        campusInvolvements: {
        },
        interests: ["Golf", "Video Games", "Gym"],
        whyLPN: "LPN has gifted me a community of life-long brotherhood.",
        img: require('../../../../assets/actives/brothers/Ben_Nguyen.jpg')
    },

    Cinta_Adhiningrat: {
        linkedIn: "https://www.linkedin.com/in/cintaadhi/",
        homeTown: "Irvine, CA",
        year: "3rd",
        class: "Psi",
        major: "Informatics",
        minor: "Film and Media",
        emphasis: "Human-Computer Interaction",
        practice: "Product",
        pronouns: "She/They",
        experience: {
            "Marketing Operations Intern": "SentinelOne",
            "Market Research Extern": "National Research Group",
            "Sophomore Summit Participant": "Abercrombie and Fitch",
            "Elevate to Innovate Extern": "Accenture",
        },
        campusInvolvements: {
            "FITS at UCI": "Vice President",
            "REACH at UCI": "VP of Outreach",
            "The Women's Network at UCI": "VP of Diversity, Equity, and Inclusion",
            "Design at UCI": "Design-a-thon Director",
        },
        interests: ["Thrifting", "Cafe Hopping", "Videography", "Collecting Vinyls and Blind Boxes", "Reposting TikToks"],
        whyLPN: "LPN broke me out of a stubborn mindset that I knew myself best. LPN will teach you more about yourself professionally and personally, especially in aspects that you may have never realized. Being a part of LPN has given me so much drive to continue improving myself in all parts of my life.",
        img: require('../../../../assets/actives/brothers/Cinta_A.jpg')
    },

    Christopher_Li: {
        linkedIn: "https://www.linkedin.com/in/chrisli191/",
        homeTown: "Irvine, CA",
        year: "2nd",
        class: "Psi",
        major: "Informatics",
        minor: "Management",
        emphasis: "Human-Computer Interaction",
        practice: "UI/UX Design, Product Management",
        pronouns: "He/Him",
        experience: {},
        campusInvolvements: {
            "Design at UCI": "Project Teams Lead",
            "Marketing Association": "Member Relations Director",
            "Chinese Association": "Member",
        },
        interests: ["Snowboarding", "Music Festivals", "Kpop", "EDM", "Grubbing", "Tennis"],
        whyLPN: "As someone who was unfamiliar with the business field as a whole coming into UCI as an informatics major, LPN has given me the chance to develop immensely in my professional aspect and pushed me to opportunities that I would not have done solely by myself. LPN also has such a loving community, something I had been searching for through my time at UCI as a first-year, and I value these group of people as my second family. In my short time here, I have already made so many memories I will cherish forever with people I care very deeply about!",
        img: require('../../../../assets/actives/brothers/Chris_Li.jpg')
    },

    Emily_Chen: {
        linkedIn: "https://www.linkedin.com/in/emilychen04/",
        homeTown: "San Leandro, CA",
        year: "2nd",
        class: "Psi",
        major: "Business Administration",
        minor: "Digital Information Systems",
        emphasis: "",
        practice: "Digital Marketing",
        pronouns: "She/Her",
        experience: {
            "Accenture": "Business Analyst Intern",
            "Revent Solutions": "Content Marketing Intern",
            "Stop AAPI Hate": "Intern",
        },
        campusInvolvements: {
            "Undergraduate Business Association": "VP of Administration",
            "Merage Undergraduate Student Association": "Freshman Representative",
            "Marketing Association": "Member Relations Director",
        },
        interests: ["Gym", "Going to the Beach", "Gel-X Nails", "Taking Pictures", "Driving"],
        whyLPN: "LPN has provided me with a supportive environment where I can be my best self professionally and personally. It has allowed me to be around people that share very similar interests and aspirations.",
        img: require('../../../../assets/actives/brothers/Emily_Chen.jpg')
    },

    Kyle_Shih: {
        linkedIn: "https://www.linkedin.com/in/kyleshih/",
        homeTown: "Huntington Beach, CA",
        year: "2nd",
        class: "Psi",
        major: "Anthropology",
        minor: "Sociology",
        emphasis: "",
        practice: "Project & Product Management",
        pronouns: "He/Him",
        experience: {
            "Gudetama Cafe": "Social Media Marketer",
            "UCI Ethics Center": "Academic Research Intern",
        },
        campusInvolvements: {
            "Undergraduate Business Association": "VP of Marketing",
            "ASUCI": "Mental Health Creative Visioning Intern",
            "Manifest at UCI": "UI/UX Designer",
        },
        interests: ["Swimming", "Going to Cafes", "Thrifting", "Paddle Boarding"],
        whyLPN: "I joined LPN because I really wanted to find a community of people that I could confide in, but also be surrounded by those that will push me outside of my comfort zone. Through LPN, I have some of my closest friends that have helped me develop personally and professionally!",
        img: require('../../../../assets/actives/brothers/Kyle_Shih.jpg')
    },

    Nathan_Hou: {
        linkedIn: "https://www.linkedin.com/in/nathanjhou/",
        homeTown: "Diamond Bar, CA",
        year: "4th",
        class: "Psi",
        major: "Business Administration",
        minor: "",
        emphasis: "Finance",
        practice: "Asset Management",
        pronouns: "He/Him",
        experience: {
            "SMBC": "Summer Analyst",
            "Inline GP": "Logistics Analyst",
            "VocoPro Electronics": "Business Analyst Intern",
        },
        campusInvolvements: {
        },
        interests: ["Volleyball", "Karting", "F1"],
        whyLPN: "I discovered the satisfaction of a rich and meaningful existence through the endless meals, late-night study sessions, and genuine support I receive from LPN.",
        img: require('../../../../assets/actives/brothers/Nathan_Hou.jpg')
    },

    Tristan_Dizon: {
        linkedIn: "https://www.linkedin.com/in/tristan-dizon/",
        homeTown: "Saratoga, CA",
        year: "4th",
        class: "Psi",
        major: "Economics",
        minor: "",
        emphasis: "",
        practice: "Accounting",
        pronouns: "He/Him",
        experience: {
            "EY": "Audit and Assurance Intern",
        },
        campusInvolvements: {
            "Beta Alpha Psi": "Secretary",
        },
        interests: ["Guitar", "Video Games", "Working Out", "Watching Movies"],
        whyLPN: "LPN had some of the most welcoming people I have met at UCI. While it is business-oriented, that is just one of the many perks of LPN. I have found such a supportive community, and joining is one of the best decisions I have made personally and professionally. ",
        img: require('../../../../assets/actives/brothers/Tristan_Dizon.jpg')
    },

    // Alpha Beta

    Keili_Ishiara: {
        linkedIn: "https://www.linkedin.com/in/keiliish/",
        homeTown: "Kane'ohe, HI",
        year: "2nd",
        class: "Alpha Beta",
        major: "Business Administration",
        minor: "",
        emphasis: "Marketing",
        practice: "Marketing",
        pronouns: "She/Her",
        experience: {
            "Bank of Hawaii": "Marketing Intern",
        },
        campusInvolvements: {
        },
        interests: ["Scrapbooking", "Traveling", "Collecting Blind Boxes", "Cafe Hopping"],
        whyLPN: "LPN has brought me countless opportunities, both professionally and personally. The community and work ethic of everyone in LPN continues to motivate and inspire me.",
        img: require('../../../../assets/actives/brothers/Keili_Ishiara.jpg')
    },

    Lauren_Wong: {
        linkedIn: "https://www.linkedin.com/in/laurenchauwong/",
        homeTown: "Irvine, CA",
        year: "1st",
        class: "Alpha Beta",
        major: "Psychology",
        minor: "",
        emphasis: "",
        practice: "UI/UX Design",
        pronouns: "She/Her",
        experience: {
            "Allergan Aesthetics": "Operations Intern",
            "Asian American Senior Citizens Service Center": "Graphic Design Intern",
        },
        campusInvolvements: {
        },
        interests: ["Guitar", "Singing", "Eating", "Cafe Hopping"],
        whyLPN: "LPN has and continues to push me to grow and step out of my comfort zone. I've gained more confidence about what path I want to pursue and found a second family in LPN.",
        img: require('../../../../assets/actives/brothers/Lauren_Wong.jpg')
    },

    Madeleine_Chen: {
        linkedIn: "https://www.linkedin.com/in/madeleinejchen/",
        homeTown: "Irvine, CA",
        year: "1st",
        class: "Alpha Beta",
        major: "Business Administration",
        minor: "",
        emphasis: "",
        practice: "Project Management and Marketing",
        pronouns: "She/Her",
        experience: {
            "Allergan Aesthetics": "Data Analytics Intern",
        },
        campusInvolvements: {
            "Merage Undergraduate Student Association": "Freshman Representative",
        },
        interests: ["Crocheting", "Swimming", "Concerts", "Traveling"],
        whyLPN: "LPN has and continues to push me to grow and step out of my comfort zone. I've gained more confidence about what path I want to pursue and found a second family in LPN.",
        img: require('../../../../assets/actives/brothers/Madeleine_Chen.jpg')
    },

    Mahad_Afridi: {
        linkedIn: "https://www.linkedin.com/in/mahad-akbar-afridi/",
        homeTown: "Lakewood, CA",
        year: "2nd",
        class: "Alpha Beta",
        major: "Computer Science",
        minor: "Statistics",
        emphasis: "Intelligent Systems",
        practice: "Software Engineering",
        pronouns: "He/Him",
        experience: {
            "Swimlane": "Software Engineer Intern",
        },
        campusInvolvements: {
            "Whiteson Lab": "Research Assistant",
        },
        interests: ["Soccer", "Baseball", "Lifting", "Watches"],
        whyLPN: "LPN has been a key motivator for me, especially as someone who commutes and often feels disconnected from campus. Joining the fraternity gave me a sense of belonging and encouraged me to take on more, both professionally and personally.",
        img: require('../../../../assets/actives/brothers/Mahad_Afridi.jpg')
    },

    Max_Zhang: {
        linkedIn: "https://www.linkedin.com/in/maxhczhang/",
        homeTown: "Vancouver, BC",
        year: "1st",
        class: "Alpha Beta",
        major: "Computer Science",
        minor: "",
        emphasis: "Intelligent Systems",
        practice: "Software Engineering",
        pronouns: "He/Him",
        experience: {
            "Silvercorp Metals Inc": "Information Technology Intern",
        },
        campusInvolvements: {
        },
        interests: ["Driving", "Traveling", "Weightlifting", "Geography"],
        whyLPN: "LPN has been an opportunity for me to not only develop professionally but also personally. From going about how to approach my career aspirations to making lasting bonds, I cherish every moment of my time with the people that have taught me these lessons.",
        img: require('../../../../assets/actives/brothers/Max_Zhang.jpg')
    },

    Neha_Yelgireddy: {
        linkedIn: "https://www.linkedin.com/in/neha-yelgireddy/",
        homeTown: "San Ramon, CA",
        year: "3rd",
        class: "Alpha Beta",
        major: "Software Engineering",
        minor: "",
        emphasis: "",
        practice: "Software Engineering",
        pronouns: "She/Her",
        experience: {
            "Penumbra Inc.": "Software Engineer Intern",
            "Aspiring Scholars Directed Research Program": "Machine Learning Researcher",
        },
        campusInvolvements: {
            "MAISS": "Mentee",
        },
        interests: ["Trying New Foods", "Going on Drives", "Tennis", "Hiking"],
        whyLPN: "As a transfer student, I was nervous about finding a community where I would fit in and feel supported. LPN has become like a second family, where I’ve had the chance to grow, learn from others, and form meaningful relationships with people who genuinely care.",
        img: require('../../../../assets/actives/brothers/Neha_Y.jpg')
    },

    Nicole_Anne_Yap: {
        linkedIn: "https://www.linkedin.com/in/nicoleanneyap/",
        homeTown: "Elk Grove, CA",
        year: "3rd",
        class: "Alpha Beta",
        major: "Informatics",
        minor: "Management",
        emphasis: "Human-Computer Interaction",
        practice: "Product Marketing & Leadership",
        pronouns: "She/Her",
        experience: {
            "Arena OC/Underworld Productions": "Social Media Marketer & Promoter",
            "Beats By Dre": "Consumer Insights & Market Intelligence Extern",
            "UC Irvine Athletics": "Digital Marketing & Creative Services Intern",
        },
        campusInvolvements: {
            "Product Association": "Product Marketing Manager & PMM Lead",
            "Manifest": "Marketing Internal Team Member",
            "Marketing Association": "Alumni Relations Director",
            "Kababayan": "2nd Tier & General Member",
            "Design at UCI": "Project Teams Participant",
        },
        interests: ["DJing", "Music Production", "Dance", "Videography & Photography"],
        whyLPN: "I chose LPN because of its dedication to professionalism and brotherhood. LPN not only gave me a better understanding of my career path, but it also gave me the community I have been looking for since the beginning of college.",
        img: require('../../../../assets/actives/brothers/Nicole_Yap.jpg')
    },

};

export default { profiles };